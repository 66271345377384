<template>
  <div class="bar-chart svg-container" ref="pieChart">
    <svg
      :width="svgWidth + svgPaddingX"
      :height="svgWidth + svgPaddingY"
      v-if="redrawToggle"
    >
      <g ref="backgroundPieChartPath" :transform="`translate(${(svgWidth + svgPaddingX) / 2}, ${(svgWidth + svgPaddingY) / 2})`">
        <path></path>
      </g>
      <g ref="pieChartPath" :transform="`translate(${(svgWidth + svgPaddingX) / 2}, ${(svgWidth + svgPaddingY) / 2})`">
        <path></path>
      </g>
      <g :transform="`translate(${(svgWidth + svgPaddingX) / 2}, ${(svgWidth + svgPaddingY) / 2})`">
        <text y="5px" dominant-baseline="middle" text-anchor="middle" font-size="50" font-weight="bold">{{data}}</text>
      </g>
    </svg>
  </div>
</template>

<script>
import { select } from 'd3-selection'
import { arc, pie } from 'd3-shape'
import { interpolate } from 'd3-interpolate'
import 'd3-transition'

export default {
  name: 'PieChart',
  props: ['count', 'data', 'svgPaddingX', 'svgPaddingY'],
  data () {
    return {
      svgWidth: 0,
      redrawToggle: true,
      chartColor: {
        null: '#07CD32',
        less: '#FFBC3A',
        more: '#FD6161'
      },
      windowWidth: window.innerWidth
    }
  },
  computed: {
    surplus () {
      return this.count - this.data
    },
    dataArray () {
      return [this.data, this.surplus]
    },
    arcWidth () {
      return (this.svgWidth * 0.15 > 30) ? 30 : this.svgWidth * 0.15
    },
    arcRadius () {
      // 計算弧形 有圓角的
      return arc()
        .innerRadius(this.svgWidth / 3)
        .outerRadius(this.svgWidth / 3 + this.arcWidth)
        .cornerRadius(this.svgWidth / 2)
    },
    arc () {
      // 計算弧形
      return arc()
        .innerRadius(this.svgWidth / 3)
        .outerRadius(this.svgWidth / 3 + this.arcWidth)
    },
    pie () {
      // 計算圓餅
      return pie().value(d => d)
    }
  },
  watch: {
    data () {
      this.animateLoad()
    }
  },
  methods: {
    tweenPie (b) {
      b.innerRadius = 0
      var i = interpolate({ startAngle: 0, endAngle: 0 }, b)
      return function (t) { return this.arc(i(t)) }
    },
    animateLoad () {
      const vm = this
      // const pieChartPath = this.$refs.pieChartPath
      // const backgroundPieChartPath = this.$refs.backgroundPieChartPath
      select(vm.$refs.pieChartPath)
        .selectAll('path')
        .data(vm.pie(vm.dataArray))
        .attr('fill', (d, i) => {
          if (this.data === 0) {
            return 'transparent'
          } else if (i === 0) {
            if (this.data > this.surplus) {
              return this.chartColor.more
            } else if (this.data <= this.surplus) {
              return this.chartColor.less
            }
          }
        })
        .transition()
        .duration(800)
        .attrTween('d', (d, index) => {
          var i = interpolate(d.startAngle + 0.1, d.endAngle)
          return (t) => {
            d.endAngle = i(t)
            if (index) {
              return this.arc(d)
            } else {
              return this.arcRadius(d)
            }
          }
        })

      select(vm.$refs.backgroundPieChartPath)
        .selectAll('path')
        .data(this.pie([this.surplus]))
        .attr('fill', (d, i) => {
          if (this.data === 0) {
            return this.chartColor.null
          } else {
            return '#c4c4c4'
          }
        })
        .transition()
        .duration(900)
        .attrTween('d', (d, index) => {
          var i = interpolate(d.startAngle + 0.1, d.endAngle)
          return (t) => {
            d.endAngle = i(t)
            if (index) {
              return this.arc(d)
            } else {
              return this.arcRadius(d)
            }
          }
        })
    },
    addResizeListener () {
      window.addEventListener('resize', () => {
        const vm = this
        if (vm.windowWidth === window.innerWidth) return
        vm.redrawToggle = false
        setTimeout(() => {
          vm.redrawToggle = true
          vm.$nextTick(function () {
            this.svgWidth = Number(
              this.$refs.pieChart.offsetWidth - this.svgPaddingX
            )
            this.animateLoad()
          })
        }, 0)
      })
    }
  },
  created () {
  },
  mounted () {
    this.$nextTick(function () {
      this.svgWidth = Number(
        this.$refs.pieChart.offsetWidth - this.svgPaddingX
      )
      this.animateLoad()
      this.addResizeListener()
    })
  }
}
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 1%;
  vertical-align: top;
  overflow: hidden;
}
</style>
